// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body {
    background-color: #0F0F0F;
    margin: 0;
    padding: 0;
    -ms-overflow-style: none; /* Убираем полосу в IE и Edge */
    scrollbar-width: none; /* Убираем полосу в Firefox */
    /*overflow: hidden;*/
}


html::-webkit-scrollbar,
body::-webkit-scrollbar {
    display: none; /* Убираем полосу в Webkit-браузерах (Chrome, Safari) */
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,SAAS;IACT,UAAU;IACV,wBAAwB,EAAE,+BAA+B;IACzD,qBAAqB,EAAE,6BAA6B;IACpD,oBAAoB;AACxB;;;AAGA;;IAEI,aAAa,EAAE,uDAAuD;AAC1E","sourcesContent":["html, body {\n    background-color: #0F0F0F;\n    margin: 0;\n    padding: 0;\n    -ms-overflow-style: none; /* Убираем полосу в IE и Edge */\n    scrollbar-width: none; /* Убираем полосу в Firefox */\n    /*overflow: hidden;*/\n}\n\n\nhtml::-webkit-scrollbar,\nbody::-webkit-scrollbar {\n    display: none; /* Убираем полосу в Webkit-браузерах (Chrome, Safari) */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
