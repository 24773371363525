import React from "react";
import { WalletDropdown } from "./WalletDropDown";
import { Assets } from "./Assets";
import { IUserWallet } from "../shared/intefaces/IUserWallet";
import { css } from "@emotion/css";

const walletsContentStyles = {
  dropDownContainer: css`
    margin-bottom: 32px;
  `,
};

interface WalletsContentProps {
  walletType: "my" | "another";
  wallets: IUserWallet[];
  selectedWallet: IUserWallet | null;
}

export const WalletsContent: React.FC<WalletsContentProps> = ({
  walletType,
  wallets,
  selectedWallet,
}) => {
  return (
    <div>
      <div className={walletsContentStyles.dropDownContainer}>
        <WalletDropdown
          walletType={walletType}
          selectedWallet={selectedWallet}
        />
      </div>
      <Assets wallets={wallets} />
    </div>
  );
};
