import React from "react";
import { css } from "@emotion/css";
import { PlusIcon } from "../icons/PlusIcon";

const smallButtonStyles = {
  button: css`
    display: flex;
    justify-content: center;
    padding: 8px 12px;
    width: 168px;
    background: #1c1c1c;
    color: #cebcff;
    font-size: 16px;
    font-weight: 700;
    font-family: "Space Mono", sans-serif;
    border: none;
    border-radius: 32px;
    cursor: pointer;
    outline: none;
    gap: 8px;
    white-space: nowrap;
    transition:
      background-color 0.3s,
      color 0.3s;

    &:hover {
      background: #262626;
    }
  `,
  icon: css`
    padding-top: 2px;
    width: 14px;
    height: 14px;
    transition: fill 0.3s;
  `,
};

interface SmallButtonProps {
  children: React.ReactNode;
  onClick: () => void;
}

export const SmallButton: React.FC<SmallButtonProps> = ({
  children,
  onClick,
}) => {
  return (
    <div className={smallButtonStyles.button} onClick={onClick}>
      <span className={smallButtonStyles.icon}>
        <PlusIcon />
      </span>
      {children}
    </div>
  );
};
