import { IUserWallet } from "../intefaces/IUserWallet";
import { fetchTokens } from "./fetchTokens";
import { fetchNFTs } from "./fetchNFTs";
import { fetchProtocols } from "./fetchProtocols";

export const fetchWalletData = async (
  wallet: IUserWallet,
  chain: string,
  token: string,
): Promise<{
  wallet: IUserWallet;
  tokens: any[];
  nfts: any[];
  protocols: any[];
  totalValue: number;
}> => {
  const calculateTotalValue = (...groups: any[][]): number =>
    groups.reduce(
      (total, group) =>
        total + group.reduce((sum, item) => sum + (item.value || 0), 0),
      0,
    );

  try {
    const address = wallet.address;

    // Параллельное получение данных
    const [tokens, nfts, protocols] = await Promise.all([
      fetchTokens(address, chain, token, wallet.name),
      fetchNFTs(address, chain, token, wallet.name),
      fetchProtocols(address, chain, token, wallet.name),
    ]);

    // Сортировка групп данных

    // Подсчет общего value кошелька
    const totalValue = calculateTotalValue(tokens, nfts, protocols);

    return {
      wallet,
      tokens: tokens,
      nfts: nfts,
      protocols: protocols,
      totalValue,
    };
  } catch (error) {
    return {
      wallet,
      tokens: [],
      nfts: [],
      protocols: [],
      totalValue: 0,
    };
  }
};
