import React from "react";
import { css } from "@emotion/css";
import { CryptoIcon } from "./uiKit/CryptoIcon";
import { getFormattedValue } from "../shared/utils/getFormattedValue";
import { EmptyPicture } from "./EmptyPicture";

const tokenInfoStyles = {
  container: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Open Sans", sans-serif;
  `,
  left: css`
    display: flex;
    align-items: center;
    gap: 12px;
  `,

  nameContainer: css`
    display: flex;
    align-items: start;
    gap: 4px;
    width: 100%;
  `,
  protocolName: css`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.43px;
    max-width: 88px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #ffffff;
  `,
  tag: css`
    display: flex;
    padding: 2px 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 2px;
    background: #1c1c1c;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: -0.43px;
    color: #c1c1c899;
  `,
  value: css`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.43px;
    color: #c1c1c899;
  `,
  imageWrapper: css`
    width: 32px;
    height: 32px;
    border-radius: 100%;
    overflow: hidden;
  `,
};

interface IProtocolInfoProps {
  protocolLogo: string;
  networkLogo: string;
  protocolName: string;
  tag: string;
  value: number;
}

export const ProtocolInfo: React.FC<IProtocolInfoProps> = ({
  protocolLogo,
  networkLogo,
  protocolName,
  tag,
  value,
}) => {
  return (
    <div className={tokenInfoStyles.container}>
      <div className={tokenInfoStyles.left}>
        <div style={{ position: "relative" }}>
          {protocolLogo ? (
            <CryptoIcon src={protocolLogo} size="large" />
          ) : (
            <div className={tokenInfoStyles.imageWrapper}>
              <EmptyPicture />
            </div>
          )}
          <CryptoIcon
            src={networkLogo}
            size="small-medium"
            alt="network"
            className={css`
              position: absolute;
              bottom: 0;
              right: -4px;
              border: 1px solid #0f0f0f;
            `}
          />
        </div>
        <div>
          <div className={tokenInfoStyles.nameContainer}>
            <span className={tokenInfoStyles.protocolName}>{protocolName}</span>
            {tag && <div className={tokenInfoStyles.tag}>{tag}</div>}
          </div>
          <div className={tokenInfoStyles.value}>
            ${getFormattedValue(value)}
          </div>
        </div>
      </div>
    </div>
  );
};
