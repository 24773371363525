import React from "react";
import { css } from "@emotion/css";

const tabsStyles = {
  container: (variant: "primary" | "secondary") => css`
    display: flex;
    ${variant === "primary" ? "gap: 12px;" : "gap: 20px;"}
    border-radius: 24px;
    ${variant === "primary" ? "padding: 4px;" : ""}
    ${variant === "primary"
      ? "border: 1px solid rgba(124, 124, 124, 0.5);"
      : ""}
  `,
  primaryTab: (isActive: boolean) => css`
    flex: 1;
    text-align: center;
    padding: 8px 24px;
    border-radius: 100px;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
    background-color: ${isActive ? "#1C1C1C" : "transparent"};
    color: ${isActive ? "#CEBCFF" : "#C1C1C899"};
  `,
  secondaryTab: (isActive: boolean) => css`
    display: inline-block;
    font-family: "Space Mono", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 125% */
    letter-spacing: -0.43px;
    cursor: pointer;
    padding-bottom: 4px;
    color: ${isActive ? "#ffffff" : "#888888"};
    border-bottom: ${isActive ? "2px solid #CEBCFF" : "2px solid transparent"};
    transition:
      color 0.3s,
      border-bottom 0.3s;
  `,
};

interface ITab {
  label: string;
}

interface ITabsProps {
  tabs: ITab[];
  activeIndex: number;
  onTabChange: (index: number) => void;
  variant?: "primary" | "secondary";
}

export const Tabs: React.FC<ITabsProps> = ({
  tabs,
  activeIndex,
  onTabChange,
  variant = "primary",
}) => {
  return (
    <div className={tabsStyles.container(variant)}>
      {tabs.map((tab, index) => (
        <div
          key={index}
          className={
            variant === "primary"
              ? tabsStyles.primaryTab(activeIndex === index)
              : tabsStyles.secondaryTab(activeIndex === index)
          }
          onClick={() => onTabChange(index)}
        >
          {tab.label}
        </div>
      ))}
    </div>
  );
};
