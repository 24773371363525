export const ZoomIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.453125 12.9375C0.453125 11.1667 0.786458 9.5 1.45312 7.9375C2.11979 6.375 3.04688 5.00521 4.23438 3.82812C5.42188 2.64062 6.79167 1.71354 8.34375 1.04688C9.90625 0.380208 11.5781 0.046875 13.3594 0.046875C15.1406 0.046875 16.8073 0.380208 18.3594 1.04688C19.9219 1.71354 21.2969 2.64062 22.4844 3.82812C23.6719 5.00521 24.599 6.375 25.2656 7.9375C25.9323 9.5 26.2656 11.1667 26.2656 12.9375C26.2656 14.2708 26.0677 15.5417 25.6719 16.75C25.2865 17.9583 24.7448 19.0677 24.0469 20.0781L30.7812 26.8438C31.0312 27.1042 31.2188 27.3906 31.3438 27.7031C31.4792 28.026 31.5469 28.3698 31.5469 28.7344C31.5469 29.2344 31.4323 29.6875 31.2031 30.0938C30.974 30.5 30.6615 30.8177 30.2656 31.0469C29.8698 31.2865 29.4115 31.4062 28.8906 31.4062C28.5365 31.4062 28.1927 31.3438 27.8594 31.2188C27.526 31.0938 27.2292 30.901 26.9688 30.6406L20.1719 23.8438C19.1927 24.4688 18.125 24.9635 16.9688 25.3281C15.8229 25.6823 14.6198 25.8594 13.3594 25.8594C11.5781 25.8594 9.90625 25.526 8.34375 24.8594C6.79167 24.1927 5.42188 23.2656 4.23438 22.0781C3.04688 20.8906 2.11979 19.5156 1.45312 17.9531C0.786458 16.3906 0.453125 14.7188 0.453125 12.9375ZM4.14062 12.9375C4.14062 14.2188 4.375 15.4167 4.84375 16.5312C5.32292 17.6458 5.98438 18.625 6.82812 19.4688C7.68229 20.3125 8.66667 20.974 9.78125 21.4531C10.8958 21.9323 12.0885 22.1719 13.3594 22.1719C14.6302 22.1719 15.8229 21.9323 16.9375 21.4531C18.0521 20.974 19.0312 20.3125 19.875 19.4688C20.7188 18.625 21.3802 17.6458 21.8594 16.5312C22.3385 15.4167 22.5781 14.2188 22.5781 12.9375C22.5781 11.6667 22.3385 10.4792 21.8594 9.375C21.3802 8.26042 20.7188 7.28125 19.875 6.4375C19.0312 5.58333 18.0521 4.91667 16.9375 4.4375C15.8229 3.95833 14.6302 3.71875 13.3594 3.71875C12.0885 3.71875 10.8958 3.95833 9.78125 4.4375C8.66667 4.91667 7.68229 5.58333 6.82812 6.4375C5.98438 7.28125 5.32292 8.26042 4.84375 9.375C4.375 10.4792 4.14062 11.6667 4.14062 12.9375Z"
        fill="#C1C1C8"
        fillOpacity="0.6"
      />
    </svg>
  );
};
