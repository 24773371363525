import { IUserWallet } from "../intefaces/IUserWallet";
import { BACKEND_API } from "../constants/urls";

export const updateWallets = async (
  wallets: IUserWallet[],
  token: string,
): Promise<void> => {
  try {
    const response = await fetch(`${BACKEND_API}/wallets/update`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ wallets }),
    });

    const data = await response.json();

    if (response.status !== 200 || data.status !== 200) {
      throw new Error("Failed to update wallets");
    }
  } catch (error) {
    throw error;
  }
};
