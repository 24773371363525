export const PlusIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.390625 7.35938C0.390625 7.08333 0.489583 6.84635 0.6875 6.64844C0.885417 6.45052 1.1224 6.35156 1.39844 6.35156H6V1.75781C6 1.48177 6.09635 1.24479 6.28906 1.04688C6.48177 0.848958 6.71875 0.75 7 0.75C7.27604 0.75 7.51302 0.848958 7.71094 1.04688C7.90885 1.24479 8.00781 1.48177 8.00781 1.75781V6.35156H12.6094C12.8802 6.35156 13.1146 6.45052 13.3125 6.64844C13.5104 6.84635 13.6094 7.08333 13.6094 7.35938C13.6094 7.64062 13.5104 7.88021 13.3125 8.07812C13.1146 8.27083 12.8802 8.36719 12.6094 8.36719H8.00781V12.9688C8.00781 13.2396 7.90885 13.474 7.71094 13.6719C7.51302 13.8698 7.27604 13.9688 7 13.9688C6.71875 13.9688 6.48177 13.8698 6.28906 13.6719C6.09635 13.474 6 13.2396 6 12.9688V8.36719H1.39844C1.1224 8.36719 0.885417 8.27083 0.6875 8.07812C0.489583 7.88021 0.390625 7.64062 0.390625 7.35938Z"
        fill="#CEBCFF"
      />
    </svg>
  );
};
