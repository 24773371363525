export const getFormattedValue = (value: number): string => {
  if (value === 0 || value === null) {
    return "0";
  } else if (value < 0.000001) {
    return "<0.000001";
  } else if (value < 1) {
    return value.toFixed(6);
  } else if (value >= 1000000000) {
    return `${(value / 1000000000).toFixed(1)}B`;
  } else if (value >= 1000000) {
    return `${(value / 1000000).toFixed(1)}M`;
  } else {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(value);
  }
};
