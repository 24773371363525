export const AUTHORIZATION = "authorization";

export const PORTFOLIO = "portfolio";

export const MANAGE_WALLETS = "manage-wallets";

export const WALLETS_FILTER = "wallets-filter";

export const MY_WALLETS = "my-wallets";

export const ANOTHER_WALLETS = "another-wallets";
