import React from "react";
import { css } from "@emotion/css";
import { CheckIcon } from "../icons/CheckIcon";

const checkboxStyles = {
  container: css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 6px;
    border: 1px solid rgba(124, 124, 124, 0.5);
    background: #1c1c1c;
    cursor: pointer;
    transition: all 0.3s;
  `,
  active: css`
    border-color: #cebcff;
    background: #cebcff;
  `,
};

interface ICheckboxProps {
  isChecked: boolean;
  onChange: (checked: boolean) => void;
}

export const Checkbox = ({ isChecked, onChange }: ICheckboxProps) => {
  const handleToggle = () => {
    onChange(!isChecked);
  };

  return (
    <div
      className={`${checkboxStyles.container} ${
        isChecked ? checkboxStyles.active : ""
      }`}
      onClick={handleToggle}
    >
      {isChecked && <CheckIcon />}
    </div>
  );
};
