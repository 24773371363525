export const FrogIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.8701 17.5634L36.0002 16.875L40.1303 17.5634C41.5018 17.7919 42.7502 16.7343 42.7502 15.344V13.5C42.7502 12.2574 43.7576 11.25 45.0002 11.25H52.8287C53.5629 11.25 54.251 11.6082 54.672 12.2097L60.9937 21.2407C61.5253 22.0001 61.5367 23.0078 61.0225 23.779L58.8756 26.9994C58.0953 28.1699 58.5684 29.7614 59.8614 30.3155L64.6354 32.3615C65.0366 32.5335 65.3791 32.8184 65.6212 33.1815L69.7805 39.4205C70.754 40.8806 69.7585 42.8806 68.02 43.1199C58.0927 44.4867 42.2697 49.6774 36.0002 63C29.7308 49.6774 13.9077 44.4867 3.98044 43.1199C2.24195 42.8806 1.24646 40.8806 2.2199 39.4205L6.37919 33.1815C6.6213 32.8184 6.96381 32.5335 7.36499 32.3615L12.139 30.3155C13.432 29.7614 13.9051 28.1699 13.1248 26.9994L10.9779 23.779C10.4637 23.0078 10.4752 22.0001 11.0067 21.2407L17.3284 12.2097C17.7495 11.6082 18.4375 11.25 19.1717 11.25H27.0002C28.2429 11.25 29.2502 12.2574 29.2502 13.5V15.344C29.2502 16.7343 30.4987 17.7919 31.8701 17.5634ZM21.3044 23.5898L19.048 22.4616C17.8757 21.8755 17.4505 20.4154 18.1248 19.2915L19.5943 16.8424C20.001 16.1647 20.7358 15.7459 21.5144 15.882C22.4094 16.0384 23.6494 16.3803 24.7948 17.1274C25.5086 17.593 25.7045 18.5107 25.435 19.3192L24.4451 22.2889C24.0121 23.5878 22.5291 24.2021 21.3044 23.5898ZM52.9515 22.4616L50.6951 23.5898C49.4705 24.2021 47.9874 23.5878 47.5544 22.2889L46.5645 19.3192C46.295 18.5107 46.4909 17.593 47.2047 17.1274C48.3501 16.3803 49.5901 16.0384 50.4851 15.882C51.2637 15.7459 51.9986 16.1647 52.4052 16.8424L53.8747 19.2915C54.549 20.4154 54.1238 21.8755 52.9515 22.4616ZM35.9998 30.375C28.7998 30.375 22.4998 34.125 20.2498 36C20.2498 36 26.9998 43.875 35.9998 43.875C44.9998 43.875 51.7498 36 51.7498 36C49.4998 34.125 43.1998 30.375 35.9998 30.375Z"
        fill="#CEBCFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.8701 17.5634L36.0002 16.875L40.1303 17.5634C41.5018 17.7919 42.7502 16.7343 42.7502 15.344V13.5C42.7502 12.2574 43.7576 11.25 45.0002 11.25H52.8287C53.5629 11.25 54.251 11.6082 54.672 12.2097L60.9937 21.2407C61.5253 22.0001 61.5367 23.0078 61.0225 23.779L58.8756 26.9994C58.0953 28.1699 58.5684 29.7614 59.8614 30.3155L64.6354 32.3615C65.0366 32.5335 65.3791 32.8184 65.6212 33.1815L69.7805 39.4205C70.754 40.8806 69.7585 42.8806 68.02 43.1199C58.0927 44.4867 42.2697 49.6774 36.0002 63C29.7308 49.6774 13.9077 44.4867 3.98044 43.1199C2.24195 42.8806 1.24646 40.8806 2.2199 39.4205L6.37919 33.1815C6.6213 32.8184 6.96381 32.5335 7.36499 32.3615L12.139 30.3155C13.432 29.7614 13.9051 28.1699 13.1248 26.9994L10.9779 23.779C10.4637 23.0078 10.4752 22.0001 11.0067 21.2407L17.3284 12.2097C17.7495 11.6082 18.4375 11.25 19.1717 11.25H27.0002C28.2429 11.25 29.2502 12.2574 29.2502 13.5V15.344C29.2502 16.7343 30.4987 17.7919 31.8701 17.5634ZM21.3044 23.5898L19.048 22.4616C17.8757 21.8755 17.4505 20.4154 18.1248 19.2915L19.5943 16.8424C20.001 16.1647 20.7358 15.7459 21.5144 15.882C22.4094 16.0384 23.6494 16.3803 24.7948 17.1274C25.5086 17.593 25.7045 18.5107 25.435 19.3192L24.4451 22.2889C24.0121 23.5878 22.5291 24.2021 21.3044 23.5898ZM52.9515 22.4616L50.6951 23.5898C49.4705 24.2021 47.9874 23.5878 47.5544 22.2889L46.5645 19.3192C46.295 18.5107 46.4909 17.593 47.2047 17.1274C48.3501 16.3803 49.5901 16.0384 50.4851 15.882C51.2637 15.7459 51.9986 16.1647 52.4052 16.8424L53.8747 19.2915C54.549 20.4154 54.1238 21.8755 52.9515 22.4616ZM35.9998 30.375C28.7998 30.375 22.4998 34.125 20.2498 36C20.2498 36 26.9998 43.875 35.9998 43.875C44.9998 43.875 51.7498 36 51.7498 36C49.4998 34.125 43.1998 30.375 35.9998 30.375Z"
        fill="url(#paint0_linear_5_1297)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5_1297"
          x1="10.5"
          y1="57"
          x2="80.5"
          y2="11"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B590EC" />
          <stop offset="0.58" stopColor="#A7BBED" />
          <stop offset="1" stopColor="#AED7DB" />
        </linearGradient>
      </defs>
    </svg>
  );
};
