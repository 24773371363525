import React from "react";

export const InfoIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5 20.3281C10.401 20.3281 9.36979 20.1198 8.40625 19.7031C7.44271 19.2917 6.59635 18.7214 5.86719 17.9922C5.13802 17.2578 4.5651 16.4115 4.14844 15.4531C3.73698 14.4896 3.53125 13.4583 3.53125 12.3594C3.53125 11.2604 3.73698 10.2292 4.14844 9.26562C4.5651 8.30208 5.13802 7.45573 5.86719 6.72656C6.59635 5.9974 7.44271 5.42708 8.40625 5.01562C9.36979 4.59896 10.401 4.39062 11.5 4.39062C12.599 4.39062 13.6302 4.59896 14.5938 5.01562C15.5573 5.42708 16.4036 5.9974 17.1328 6.72656C17.862 7.45573 18.4323 8.30208 18.8438 9.26562C19.2604 10.2292 19.4688 11.2604 19.4688 12.3594C19.4688 13.4583 19.2604 14.4896 18.8438 15.4531C18.4323 16.4115 17.862 17.2578 17.1328 17.9922C16.4036 18.7214 15.5573 19.2917 14.5938 19.7031C13.6302 20.1198 12.599 20.3281 11.5 20.3281ZM10.0938 16.875H13.3672C13.5339 16.875 13.6745 16.8229 13.7891 16.7188C13.9036 16.6094 13.9609 16.4714 13.9609 16.3047C13.9609 16.1484 13.9036 16.0156 13.7891 15.9062C13.6745 15.7917 13.5339 15.7344 13.3672 15.7344H12.375V11.6484C12.375 11.4297 12.3203 11.2526 12.2109 11.1172C12.1068 10.9818 11.9505 10.9141 11.7422 10.9141H10.2266C10.0651 10.9141 9.92708 10.9714 9.8125 11.0859C9.69792 11.1953 9.64062 11.3281 9.64062 11.4844C9.64062 11.651 9.69792 11.7891 9.8125 11.8984C9.92708 12.0026 10.0651 12.0547 10.2266 12.0547H11.0859V15.7344H10.0938C9.92708 15.7344 9.78646 15.7917 9.67188 15.9062C9.55729 16.0156 9.5 16.1484 9.5 16.3047C9.5 16.4714 9.55729 16.6094 9.67188 16.7188C9.78646 16.8229 9.92708 16.875 10.0938 16.875ZM11.4297 9.57812C11.7266 9.57812 11.974 9.47656 12.1719 9.27344C12.375 9.0651 12.4766 8.8151 12.4766 8.52344C12.4766 8.22656 12.375 7.97656 12.1719 7.77344C11.974 7.5651 11.7266 7.46094 11.4297 7.46094C11.138 7.46094 10.888 7.5651 10.6797 7.77344C10.4766 7.97656 10.375 8.22656 10.375 8.52344C10.375 8.8151 10.4766 9.0651 10.6797 9.27344C10.888 9.47656 11.138 9.57812 11.4297 9.57812Z"
      fill="#C1C1C8"
      fillOpacity="0.6"
    />
  </svg>
);
