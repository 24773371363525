import React from "react";
import { css } from "@emotion/css";
import { NoPic } from "./icons/NoPic";

const emptyPictureStyles = {
  container: css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #1c1c1c;
  `,
};

export const EmptyPicture: React.FC = () => {
  return (
    <div className={emptyPictureStyles.container}>
      <NoPic />
    </div>
  );
};
