import React, { ReactNode } from "react";
import { css } from "@emotion/css";

const buttonStyles = {
  base: css`
    display: inline-flex;
    cursor: pointer;
    width: 100%;
    height: 52px;
    align-items: center;
    justify-content: center;
    font-family: "Space Mono", sans-serif;
    font-weight: 700;
    border: none;
    border-radius: 32px;
    padding: 16px;
    font-size: 16px;
    font-style: normal;
  `,
  variants: {
    light: css`
      background-color: #cebcff;
      color: #020013;

      &:hover {
        background-color: #dccfff;
      }
    `,
    dark: css`
      background-color: #1c1c1c;
      color: #cebcff;

      &:hover {
        background-color: #262626;
      }
    `,
  },
};

interface ButtonProps {
  variant?: "light" | "dark";
  children: ReactNode;
  onClick?: () => void;
}

export const Button: React.FC<ButtonProps> = ({
  variant = "light",
  children,
  onClick,
}) => {
  const variantStyle =
    buttonStyles.variants[variant] || buttonStyles.variants.light;

  return (
    <button
      className={`${buttonStyles.base} ${variantStyle}`}
      onClick={onClick}
    >
      <span>{children}</span>
    </button>
  );
};
