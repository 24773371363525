import React, { useEffect } from "react";
import { css } from "@emotion/css";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserWallets } from "../context/UserWalletContext";
import { Button } from "../uiKit/Button";
import { FilterWalletList } from "../FilterWalletList";
import { IUserWallet } from "../../shared/intefaces/IUserWallet";
import {
  ANOTHER_WALLETS,
  AUTHORIZATION,
  MANAGE_WALLETS,
  MY_WALLETS,
  PORTFOLIO,
} from "../../shared/constants/routes";
import { BackButton } from "../uiKit/BackButton";

const walletFilterPageStyles = {
  container: css`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 16px 16px 32px;
    box-sizing: border-box;
  `,
  backButton: css`
    margin-bottom: 24px;
  `,
  header: css`
    font-family: "Space Mono", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    color: #ffffff;
    margin-bottom: 24px;
  `,
  content: css`
    margin-bottom: 16px;
  `,
  footer: css`
    margin-top: auto;
  `,
  loadingMessage: css`
    font-family: "Space Mono", sans-serif;
    font-size: 16px;
    color: #fff;
    text-align: center;
    margin-top: 40vh;
  `,
};

export const WalletFilterPage: React.FC = () => {
  const { userWallets, setMainWallet, setAnotherWallet, loading } =
    useUserWallets();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!loading && userWallets.length === 0) {
      navigate(`/${AUTHORIZATION}`);
    }
  }, [loading, userWallets, navigate]);

  const walletType = location.state?.type || "my";
  const filteredWallets = userWallets.filter((wallet) =>
    walletType === "my" ? wallet.isMyWallet : !wallet.isMyWallet,
  );

  const handleWalletSelect = (wallet: IUserWallet | null) => {
    if (wallet === null) {
      setMainWallet(null);
      navigate(`/${PORTFOLIO}/${MY_WALLETS}`);
    } else {
      if (walletType === "my") {
        setMainWallet(wallet);
        navigate(`/${PORTFOLIO}/${MY_WALLETS}`);
      } else if (walletType === "another") {
        setAnotherWallet(wallet);
        navigate(`/${PORTFOLIO}/${ANOTHER_WALLETS}`);
      }
    }
  };

  if (loading) {
    return (
      <div className={walletFilterPageStyles.loadingMessage}>
        Loading wallets...
      </div>
    );
  }

  return (
    <div className={walletFilterPageStyles.container}>
      <div className={walletFilterPageStyles.backButton}>
        <BackButton onClick={() => navigate(-1)} />
      </div>

      <div className={walletFilterPageStyles.header}>Select wallet</div>

      <div className={walletFilterPageStyles.content}>
        <FilterWalletList
          walletType={walletType}
          wallets={filteredWallets}
          onWalletSelect={handleWalletSelect}
        />
      </div>

      <div className={walletFilterPageStyles.footer}>
        <Button variant="dark" onClick={() => navigate(`/${MANAGE_WALLETS}`)}>
          Manage Wallets
        </Button>
      </div>
    </div>
  );
};
