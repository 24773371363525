import React from "react";
import { css } from "@emotion/css";
import { getShortAddress } from "../shared/utils/getShortAddress";

const walletListItemStyles = {
  walletItem: (isSelected: boolean) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    border-radius: 8px;
    font-family: "Open Sans", sans-serif;

    border: 1px solid ${isSelected ? "#CEBCFF" : "#C1C1C899"};
    cursor: pointer;
    margin-bottom: 12px;
  `,
  walletDetails: css`
    display: flex;
    flex-direction: column;
    gap: 2px;
  `,
  walletAddress: css`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #ffffff;
  `,
  walletName: css`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.43px;
    color: #c1c1c899;
  `,
};

interface FilterWalletListItemProps {
  walletAddress: string;
  walletName: string;
  isSelected: boolean;
  onClick: () => void;
}

export const FilterWalletListItem: React.FC<FilterWalletListItemProps> = ({
  walletAddress,
  walletName,
  isSelected,
  onClick,
}) => {
  return (
    <div
      className={walletListItemStyles.walletItem(isSelected)}
      onClick={onClick}
    >
      <div className={walletListItemStyles.walletDetails}>
        <span className={walletListItemStyles.walletAddress}>
          {getShortAddress(walletAddress)}
        </span>
        <span className={walletListItemStyles.walletName}>{walletName}</span>
      </div>
    </div>
  );
};
