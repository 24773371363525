import { BACKEND_API } from "../constants/urls";
import { IUserWallet } from "../intefaces/IUserWallet";

const userWalletTypeConversion = (userWallet: any): IUserWallet => {
  return {
    databaseId: userWallet.id,
    id: userWallet.id,
    chain: userWallet.chain,
    address: userWallet.address,
    name: userWallet.name,
    isMyWallet: userWallet.is_my_wallet,
    isNew: false,
    errors: { address: false, name: false, duplicate: false },
  };
};

export const getWalletsByUserId = async (userId: number, token: string) => {
  try {
    const response = await fetch(`${BACKEND_API}/wallets/get/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch wallets: ${response.statusText}`);
    }

    const data = await response.json();
    return data.result.map((x: any) => userWalletTypeConversion(x));
  } catch (error) {
    return [];
  }
};
