import React from "react";
import { css } from "@emotion/css";
import { Checkbox } from "./uiKit/CheckBox";
import { Input } from "./uiKit/Input";
import { Info } from "./Info";

export const walletCardStyles = {
  container: (isEditable: boolean) => css`
    display: flex;
    flex-direction: column;
    background: #0f0f0f;
    font-family: "Open Sans", sans-serif;

    color: #fff;
    ${isEditable
      ? `
        border: 1px solid #7c7c7c80;
        border-radius: 16px;
        padding: 16px;
      `
      : ""}
  `,
  header: css`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;
  `,
  checkboxRow: css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
  `,
  label: css`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.43px;
    color: #ffffff;
    margin-left: 12px;
    margin-right: 4px;
  `,
  actions: css`
    display: flex;
    justify-content: space-between;
  `,
  deleteButton: css`
    background: none;
    color: #f7c5c5;
    font-family: "Space Mono", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 590;
    align-content: center;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      color: #ffadad;
    }
  `,
};

export interface WalletCardProps {
  address: string;
  name: string;
  isMyWallet: boolean;
  hasAddressError?: boolean;
  addressErrorText?: string;
  hasNameError?: boolean;
  nameErrorText?: string;
  isEditable?: boolean;
  onAddressChange: (value: string) => void;
  onNameChange: (value: string) => void;
  onMyWalletChange: (checked: boolean) => void;
  onDelete?: () => void;
}

export const WalletCard: React.FC<WalletCardProps> = ({
  address,
  name,
  isMyWallet,
  hasAddressError = false,
  addressErrorText = "",
  hasNameError = false,
  nameErrorText = "",
  isEditable = false,
  onAddressChange,
  onNameChange,
  onMyWalletChange,
  onDelete,
}) => {
  return (
    <div className={walletCardStyles.container(isEditable)}>
      <div className={walletCardStyles.header}>
        <Input
          value={address}
          placeholder="Wallet address"
          hasError={hasAddressError}
          errorText={addressErrorText}
          onChange={(e) => onAddressChange(e.target.value)}
        />
        <Input
          value={name}
          placeholder="Name"
          hasError={hasNameError}
          errorText={nameErrorText}
          onChange={(e) => onNameChange(e.target.value)}
        />
      </div>
      <div className={walletCardStyles.actions}>
        <div className={walletCardStyles.checkboxRow}>
          <Checkbox isChecked={isMyWallet} onChange={onMyWalletChange} />
          <span className={walletCardStyles.label}>My wallet</span>
          <Info
            text={"This address will be included in total balance calculation"}
          />
        </div>
        {isEditable && onDelete && (
          <div className={walletCardStyles.deleteButton} onClick={onDelete}>
            Delete
          </div>
        )}
      </div>
    </div>
  );
};
