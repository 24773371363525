export const WalletsIcon = () => {
  return (
    <svg
      width="28"
      height="24"
      viewBox="0 0 28 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.72168 23.5283C2.59082 23.5283 1.73535 23.2383 1.15527 22.6582C0.575191 22.084 0.285152 21.2373 0.285152 20.1182V8.34082C0.285152 7.22168 0.575191 6.375 1.15527 5.80078C1.73535 5.22656 2.59082 4.93945 3.72168 4.93945H22.2227C23.3594 4.93945 24.2148 5.22949 24.7891 5.80957C25.3691 6.38379 25.6592 7.22754 25.6592 8.34082V9.94922H21.4668C20.5352 9.94922 19.7295 10.1309 19.0498 10.4941C18.376 10.8516 17.8545 11.3525 17.4853 11.9971C17.1162 12.6416 16.9316 13.3799 16.9316 14.2119C16.9316 15.0498 17.1162 15.7881 17.4853 16.4268C17.8603 17.0654 18.3848 17.5664 19.0586 17.9297C19.7324 18.2871 20.5352 18.4658 21.4668 18.4658H25.6592V20.1182C25.6592 21.2314 25.3691 22.0781 24.7891 22.6582C24.2148 23.2383 23.3594 23.5283 22.2227 23.5283H3.72168ZM21.2734 15.4336C21.6074 15.4336 21.8945 15.3164 22.1348 15.082C22.375 14.8418 22.4951 14.5547 22.4951 14.2207C22.4951 13.8809 22.375 13.5938 22.1348 13.3594C21.8945 13.1191 21.6074 12.999 21.2734 12.999C20.9336 12.999 20.6436 13.1191 20.4033 13.3594C20.1631 13.5938 20.043 13.8809 20.043 14.2207C20.043 14.5547 20.1631 14.8418 20.4033 15.082C20.6436 15.3164 20.9336 15.4336 21.2734 15.4336ZM21.4668 17.0068C20.8516 17.0068 20.3125 16.9014 19.8496 16.6904C19.3926 16.4736 19.0352 16.1572 18.7773 15.7412C18.5195 15.3252 18.3906 14.8154 18.3906 14.2119C18.3906 13.6084 18.5195 13.0986 18.7773 12.6826C19.0352 12.2607 19.3926 11.9443 19.8496 11.7334C20.3125 11.5225 20.8516 11.417 21.4668 11.417H25.6416C26.1396 11.417 26.5234 11.5605 26.793 11.8477C27.0684 12.1348 27.2061 12.5068 27.2061 12.9639V15.4512C27.2061 15.9082 27.0684 16.2832 26.793 16.5762C26.5234 16.8633 26.1396 17.0068 25.6416 17.0068H21.4668ZM7.58007 3.46289L17.4062 0.606445C18.2617 0.348633 19.0117 0.275391 19.6562 0.386719C20.3066 0.492188 20.8223 0.773438 21.2031 1.23047C21.5898 1.68164 21.8066 2.29687 21.8535 3.07617C21.8535 3.14062 21.8535 3.20508 21.8535 3.26953C21.8535 3.32813 21.8535 3.39258 21.8535 3.46289H7.58007Z"
        fill="currentColor"
      />
    </svg>
  );
};
