import React from "react";
import { css } from "@emotion/css";
import { useNavigate } from "react-router-dom";
import { IUserWallet } from "../shared/intefaces/IUserWallet";
import { WALLETS_FILTER } from "../shared/constants/routes";
import { DownArrowIcon } from "./icons/DownArrowIcon";
import { getShortAddress } from "../shared/utils/getShortAddress";

const dropdownStyles = {
  container: css`
    text-overflow: ellipsis;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.43px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-size: 14px;
    color: #fff;
    padding: 10px 16px;
    gap: 8px;
    border-radius: 32px;
    background: #1c1c1c;
  `,
  addressArrowBlock: css`
    display: flex;
    gap: 8px;
    color: #c1c1c899;
  `,
  arrow: css`
    margin-left: auto;
  `,
};

interface WalletDropdownProps {
  selectedWallet: IUserWallet | null;
  walletType: "my" | "another";
}

export const WalletDropdown: React.FC<WalletDropdownProps> = ({
  selectedWallet,
  walletType,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/${WALLETS_FILTER}`, { state: { type: walletType } });
  };

  return (
    <div className={dropdownStyles.container} onClick={handleClick}>
      {selectedWallet === null ? "All Wallets" : selectedWallet.name}
      <div className={dropdownStyles.addressArrowBlock}>
        <span>
          {selectedWallet !== null
            ? getShortAddress(selectedWallet.address, 5)
            : ""}
        </span>
        <span className={dropdownStyles.arrow}>
          <DownArrowIcon />
        </span>
      </div>
    </div>
  );
};
