import React, { useEffect } from "react";
import { css } from "@emotion/css";
import { useNavigate } from "react-router-dom";
import { useUserWallets } from "../context/UserWalletContext";
import { AUTHORIZATION } from "../../shared/constants/routes";
import { WalletManager } from "../WalletManager";

const manageWalletsPageStyles = {
  container: css`
    display: flex;
    flex-direction: column;
  `,
  header: css`
    padding-top: 24px;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 24px;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    letter-spacing: -0.43px;
    color: #c1c1c899;
  `,
  loadingMessage: css`
    font-size: 16px;
    color: #ffffff;
    text-align: center;
    margin-top: 40vh;
  `,
};

export const ManageWalletsPage: React.FC = () => {
  const { userWallets, loading } = useUserWallets();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && userWallets.length === 0) {
      navigate(`/${AUTHORIZATION}`);
    }
  }, [loading, userWallets, navigate]);

  if (loading) {
    return (
      <div className={manageWalletsPageStyles.loadingMessage}>
        Loading wallets...
      </div>
    );
  }

  return (
    <div className={manageWalletsPageStyles.container}>
      <div className={manageWalletsPageStyles.header}>
        Add your and other wallets to the tracking lists in the portfolio
      </div>
      <WalletManager isLayout={true} />
    </div>
  );
};
