import React from "react";
import { css } from "@emotion/css";
import { TokenInfo } from "./TokenInfo";
import { IToken } from "../shared/intefaces/IToken";
import { EmptyState } from "./EmptyState";

const cryptocurrencyListStyles = {
  container: css`
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: white;
    padding-bottom: 24px;
  `,
};

interface CryptocurrencyListProps {
  data: IToken[];
}

export const CryptocurrencyList: React.FC<CryptocurrencyListProps> = ({
  data,
}) => {
  return (
    <div className={cryptocurrencyListStyles.container}>
      {data.map((token, index) => (
        <TokenInfo
          tokenLogo={token.logoUrl}
          networkLogo={token.chainLogo}
          tag={token.walletName}
          tokenName={token.symbol}
          price={token.price}
          balance={token.amount}
          value={token.value}
          key={index}
        />
      ))}
      {data.length === 0 && <EmptyState message={"No assets found"} />}
    </div>
  );
};
