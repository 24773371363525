import { BACKEND_API } from "../constants/urls";
import { IProtocol } from "../intefaces/IProtocol";
import { capitalize } from "./capitalize";

const protocolTypeConversion = (
  protocol: any,
  walletName: string,
): IProtocol => {
  return {
    name: capitalize(protocol.protocol_name || "unknown"),
    value: protocol.net_usd_value,
    image: protocol.logo_url,
    chainLogo: protocol.chain_logo,
    chain: protocol.chain_name,
    walletName,
  };
};
export const fetchProtocols = async (
  address: string,
  chain: string,
  token: string,
  walletName: string,
): Promise<IProtocol[]> => {
  try {
    const response = await fetch(
      `${BACKEND_API}/protocols/${chain}/${address}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const data = await response.json();
    return (
      data.result.map((x: any) => protocolTypeConversion(x, walletName)) || []
    );
  } catch (error) {
    return [];
  }
};
