import { BACKEND_API } from "../constants/urls";

export const fetchJwtToken = async (
  telegramId: number,
  userName: string,
): Promise<string> => {
  try {
    const response = await fetch(`${BACKEND_API}/auth/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ telegramId, userName }),
    });

    const data = await response.json();

    if (response.status === 200 && data.status === 200 && data.token) {
      return data.token;
    } else {
      throw new Error(data.message || "Failed to fetch JWT token");
    }
  } catch (error) {
    throw error;
  }
};
