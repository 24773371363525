import React, { useEffect } from "react";
import { css } from "@emotion/css";
import { Outlet } from "react-router-dom";
import { NavigationBar } from "./NavigationBar";

export const NAVIGATION_BAR_HEIGHT = 84;

const layoutStyles = {
  container: css`
    display: flex;
    flex-direction: column;
  `,
  navBar: css`
    position: fixed;
    bottom: 0;
    width: 100%;
    max-height: ${NAVIGATION_BAR_HEIGHT}px;
  `,
  content: css`
    height: calc(100vh - ${NAVIGATION_BAR_HEIGHT}px);

    //tg safe area
    //padding-bottom: 100vh;
    overflow-y: scroll;
    ms-overflow-style: none;
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none;
    }
  `,
};

export const Layout: React.FC = () => {
  useEffect(() => {
    const disableScroll = () => {
      document.body.style.overflow = "hidden";
    };

    const enableScroll = () => {
      document.body.style.overflow = "auto";
    };

    disableScroll();

    return enableScroll;
  }, []);

  return (
    <div className={layoutStyles.container}>
      <div className={layoutStyles.content}>
        <Outlet />
      </div>
      <div className={layoutStyles.navBar}>
        <NavigationBar />
      </div>
    </div>
  );
};
