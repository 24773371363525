import { BACKEND_API } from "../constants/urls";
import { INFT } from "../intefaces/INFT";
import { capitalize } from "./capitalize";

const nftTypeConversion = (nftObject: any, walletName: string): INFT => {
  return {
    name: capitalize(nftObject.name || "unknown"),
    price: nftObject.price,
    value: nftObject.amount * nftObject.price,
    amount: nftObject.amount,
    image: nftObject.logo_url,
    chainLogo: nftObject.chain_logo,
    chain: nftObject.chain_name,
    walletName,
  };
};

export const fetchNFTs = async (
  address: string,
  chain: string,
  token: string,
  walletName: string,
): Promise<INFT[]> => {
  try {
    const response = await fetch(`${BACKEND_API}/nfts/${chain}/${address}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data.result.map((x: any) => nftTypeConversion(x, walletName)) || [];
  } catch (error) {
    return [];
  }
};
