import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { IUserWallet } from "../../shared/intefaces/IUserWallet";
import { getWalletsByUserId } from "../../shared/utils/getWalletsByUserId";
import { createWallets } from "../../shared/utils/createWallets";
import { updateWallets } from "../../shared/utils/updateWallets";
import { deleteWallets } from "../../shared/utils/deleteWallets";
import { fetchJwtToken } from "../../shared/utils/fetchJwtToken";

interface UserWalletContextProps {
  userWallets: IUserWallet[];
  loading: boolean;
  userId: number | null;
  token: string | null;
  reloadUserWallets: () => Promise<void>;
  createWallets: (wallets: IUserWallet[]) => Promise<void>;
  updateWallets: (wallets: IUserWallet[]) => Promise<void>;
  deleteWallets: (walletIds: number[]) => Promise<void>;
  mainWallet: IUserWallet | null;
  anotherWallet: IUserWallet | null;
  setMainWallet: (wallet: IUserWallet | null) => void;
  setAnotherWallet: (wallet: IUserWallet | null) => void;
}

const USER_UD = 211715292;
const USER_NAME = "roman_shb";

const UserWalletContext = createContext<UserWalletContextProps | undefined>(
  undefined,
);

export const UserWalletProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [userWallets, setUserWallets] = useState<IUserWallet[]>([]);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState<number | null>(null);
  const [userName, setUserName] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [mainWallet, setMainWalletState] = useState<IUserWallet | null>(null);
  const [anotherWallet, setAnotherWalletState] = useState<IUserWallet | null>(
    null,
  );

  useEffect(() => {
    // setUserId(USER_UD);
    // setUserName(USER_NAME);
    const telegramWebApp = (window as any).Telegram?.WebApp;
    if (telegramWebApp) {
      telegramWebApp.expand();
      telegramWebApp.disableVerticalSwipes();
      telegramWebApp.setBackgroundColor("#0F0F0F");
      const telegramData = telegramWebApp.initDataUnsafe;
      if (telegramData?.user?.id && telegramData?.user?.username) {
        setUserId(telegramData.user.id);
        setUserName(telegramData.user.username);
      } else {
        setLoading(false);
      }
    }
  }, []);

  const fetchToken = useCallback(async () => {
    if (!userId || !userName) {
      return;
    }
    try {
      const jwtToken = await fetchJwtToken(userId, userName);
      setToken(jwtToken);
    } catch (error) {}
  }, [userId, userName]);

  useEffect(() => {
    if (userId && userName) {
      fetchToken();
    }
  }, [userId, userName, fetchToken]);

  const fetchUserWallets = useCallback(async () => {
    try {
      setMainWallet(null);
      setAnotherWallet(null);
      setUserWallets([]);
      setLoading(true);
      if (userId && token) {
        const data = await getWalletsByUserId(userId, token);
        setUserWallets(data);
      }
    } catch (error) {
      setUserWallets([]);
    } finally {
      setLoading(false);
    }
  }, [userId, token]);

  const reloadUserWallets = useCallback(async () => {
    await fetchUserWallets();
  }, [fetchUserWallets]);

  useEffect(() => {
    if (userId && token) {
      fetchUserWallets();
    }
  }, [userId, token, fetchUserWallets]);

  const createWalletsHandler = async (wallets: IUserWallet[]) => {
    try {
      if (userId && token) {
        await createWallets(userId, wallets, token);
        await reloadUserWallets();
      }
    } catch (error) {}
  };

  const updateWalletsHandler = async (wallets: IUserWallet[]) => {
    try {
      if (token) {
        await updateWallets(wallets, token);
      }
      await reloadUserWallets();
    } catch (error) {}
  };

  const deleteWalletsHandler = async (walletIds: number[]) => {
    try {
      if (token) {
        await deleteWallets(walletIds, token);
      }
      await reloadUserWallets();
    } catch (error) {}
  };

  const setMainWallet = (wallet: IUserWallet | null) => {
    setMainWalletState(wallet);
  };

  const setAnotherWallet = (wallet: IUserWallet | null) => {
    setAnotherWalletState(wallet);
  };

  return (
    <UserWalletContext.Provider
      value={{
        userWallets,
        loading,
        userId,
        token,
        reloadUserWallets,
        createWallets: createWalletsHandler,
        updateWallets: updateWalletsHandler,
        deleteWallets: deleteWalletsHandler,
        mainWallet,
        anotherWallet,
        setMainWallet,
        setAnotherWallet,
      }}
    >
      {children}
    </UserWalletContext.Provider>
  );
};

export const useUserWallets = (): UserWalletContextProps => {
  const context = useContext(UserWalletContext);
  if (!context) {
    throw new Error("useUserWallets must be used within a UserWalletProvider");
  }
  return context;
};
