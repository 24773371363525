import React, { useState } from "react";
import { css } from "@emotion/css";
import { InfoIcon } from "./icons/InfoIcon";

const InfoStyles = {
  container: css`
    position: relative;
    display: inline-block;
    cursor: pointer;
  `,
  tooltip: css`
    position: absolute;
    top: 125%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #262626;
    color: #fff;
    padding: 12px 16px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    white-space: normal;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition:
      opacity 0.3s,
      visibility 0.3s;
    display: flex;
    width: 200px;
    flex-direction: column;
    align-items: flex-start;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    line-height: 18px;
    letter-spacing: -0.43px;
  `,
  tooltipVisible: css`
    opacity: 1;
    visibility: visible;
  `,
  arrow: css`
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #262626;
  `,
  iconWrapper: css`
    padding-top: 6px;
  `,
};

type InfoProps = {
  text: string;
};

export const Info: React.FC<InfoProps> = ({ text }) => {
  const [visible, setVisible] = useState(false);

  return (
    <div
      className={InfoStyles.container}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      <div className={InfoStyles.iconWrapper}>
        <InfoIcon />
      </div>
      <div
        className={`${InfoStyles.tooltip} ${
          visible ? InfoStyles.tooltipVisible : ""
        }`}
      >
        {text}
        <div className={InfoStyles.arrow} />
      </div>
    </div>
  );
};
