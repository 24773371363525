import React from "react";
import { css } from "@emotion/css";

const iconStyles = {
  base: css`
    display: inline-block;
    border-radius: 100%;
    object-fit: cover;
    overflow: hidden;
  `,
  sizes: {
    large: css`
      width: 32px;
      height: 32px;
    `,
    medium: css`
      width: 24px;
      height: 24px;
    `,

    "small-medium": css`
      width: 18px;
      height: 18px;
    `,
    small: css`
      width: 16px;
      height: 16px;
    `,
  },
};

interface ICryptoIconProps {
  src: string; // Путь к изображению
  size?: "large" | "medium" | "small-medium" | "small"; // Размер иконки
  alt?: string; // Альтернативный текст для изображения
  className?: string; // Дополнительный класс для стилей
}

export const CryptoIcon = ({
  src,
  size = "large",
  alt = "icon",
  className = "",
}: ICryptoIconProps) => {
  const sizeStyle = iconStyles.sizes[size];

  return (
    <img
      src={src}
      alt={alt}
      className={`${iconStyles.base} ${sizeStyle} ${className}`}
    />
  );
};
