import React from "react";
import { css } from "@emotion/css";

const totalBalanceStyles = {
  container: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #fff;
    margin-bottom: 32px;
  `,
  label: css`
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: #c1c1c899;
    margin-bottom: 5px;
  `,
  value: css`
    font-family: "Space Mono", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    color: #cebcff;
  `,
};

interface TotalBalanceProps {
  value: string;
}

export const TotalBalance: React.FC<TotalBalanceProps> = ({ value }) => {
  return (
    <div className={totalBalanceStyles.container}>
      <div className={totalBalanceStyles.label}>Total</div>
      <div className={totalBalanceStyles.value}>${value}</div>
    </div>
  );
};
