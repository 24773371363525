import React from "react";
import { css } from "@emotion/css";
import { getFormattedValue } from "../shared/utils/getFormattedValue";
import { CryptoIcon } from "./uiKit/CryptoIcon";
import { EmptyPicture } from "./EmptyPicture";

const nftInfoStyles = {
  container: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Open Sans", sans-serif;
  `,
  left: css`
    display: flex;
    align-items: center;
    gap: 12px;
  `,
  nftImageWrapper: css`
    position: relative;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  nftImage: css`
    width: 100%;
    height: 100%;
    border-radius: 4px;
    overflow: hidden;
  `,
  chainIconWrapper: css`
    position: absolute;
    bottom: -4px;
    right: -4px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  chainIcon: css`
    border-radius: 50%;
    border: 1px solid #0f0f0f;
  `,
  right: css`
    text-align: right;
  `,
  nameContainer: css`
    display: flex;
    align-items: start;
    gap: 4px;
    width: 100%;
    max-width: 200px;
  `,
  nftName: css`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.43px;
    //max-width: 88px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #ffffff;
  `,
  tag: css`
    display: flex;
    padding: 2px 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 2px;
    background: #1c1c1c;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: -0.43px;
    color: #c1c1c899;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  price: css`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.43px;
    color: #c1c1c899;
  `,
  balance: css`
    text-overflow: ellipsis;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.43px;
    color: #ffffff;
  `,
  value: css`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.43px;
    color: #c1c1c899;
  `,
};

interface INFTInfoProps {
  nftImage?: string;
  chainImage: string;
  nftName: string;
  tag?: string;
  price: number;
  balance: number;
  value: number;
}

export const NFTInfo: React.FC<INFTInfoProps> = ({
  nftImage,
  chainImage,
  nftName,
  tag,
  price,
  balance,
  value,
}) => {
  const isVideo = nftImage && nftImage.endsWith(".mov");

  return (
    <div className={nftInfoStyles.container}>
      <div className={nftInfoStyles.left}>
        <div className={nftInfoStyles.nftImageWrapper}>
          {nftImage ? (
            isVideo ? (
              <video
                src={nftImage}
                loop
                muted
                className={nftInfoStyles.nftImage}
              />
            ) : (
              <img
                src={nftImage}
                alt="NFT"
                className={nftInfoStyles.nftImage}
              />
            )
          ) : (
            <div className={nftInfoStyles.nftImage}>
              <EmptyPicture />
            </div>
          )}
          <div className={nftInfoStyles.chainIconWrapper}>
            <CryptoIcon
              src={chainImage}
              size="small-medium"
              className={nftInfoStyles.chainIcon}
            />
          </div>
        </div>
        <div>
          <div className={nftInfoStyles.nameContainer}>
            <span className={nftInfoStyles.nftName}>{nftName}</span>
            {tag && <div className={nftInfoStyles.tag}>{tag}</div>}
          </div>
          <div className={nftInfoStyles.price}>${getFormattedValue(price)}</div>
        </div>
      </div>
      <div className={nftInfoStyles.right}>
        <div className={nftInfoStyles.balance}>
          {"x" + getFormattedValue(balance)}
        </div>
        <div className={nftInfoStyles.value}>${getFormattedValue(value)}</div>
      </div>
    </div>
  );
};
