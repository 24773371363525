import React, { useState } from "react";
import { css } from "@emotion/css";
import { WalletCard } from "./WalletCard";
import { Button } from "./uiKit/Button";
import { useUserWallets } from "./context/UserWalletContext";
import { IUserWallet } from "../shared/intefaces/IUserWallet";
import { walletDefaulValue } from "../shared/constants/walletDefaulValue";
import { validateWallet } from "../shared/utils/validateWallet";
import { isAddress } from "viem";
import {
  ADDRESS_ERROR,
  DUPLICATE_ERROR,
  NAME_EMPTY_ERROR,
  NAME_LENGTH_ERROR,
} from "../shared/constants/errors";
import { useNavigate } from "react-router-dom";
import { SmallButton } from "./uiKit/SmallButton";
import { NAME_MAX_LENGTH } from "../shared/constants/numbers";
import { PORTFOLIO } from "../shared/constants/routes";
import { NAVIGATION_BAR_HEIGHT } from "./Layout";

const MAX_WALLETS_COUNT = 10;

const walletManagerStyles = {
  container: css`
    display: flex;
    flex-direction: column;
    margin-bottom: 240px;
  `,
  walletsList: css`
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 16px;
  `,
  fixedSaveButton: (height: number) => css`
    position: fixed;
    width: 100%;
    bottom: ${height}px;
    background: #0f0f0f;
    border-top: 1px solid #333;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 10000;
  `,
  buttonWrapper: (height: number) => css`
    padding: 16px 16px ${height}px 16px;
  `,
};

interface IWalletManagerProps {
  isLayout: boolean;
}

export const WalletManager = ({ isLayout }: IWalletManagerProps) => {
  const navigate = useNavigate();
  const {
    userWallets,
    createWallets,
    updateWallets,
    deleteWallets,
    reloadUserWallets,
  } = useUserWallets();
  const [wallets, setWallets] = useState<IUserWallet[]>(
    userWallets.length
      ? userWallets.map((wallet) => ({
          ...wallet,
          isNew: false,
          errors: { address: false, name: false, duplicate: false },
        }))
      : [
          {
            ...walletDefaulValue,
            errors: { address: false, name: false, duplicate: false },
          },
        ],
  );

  const validateAllWallets = () => {
    let isValid = true;
    const updatedWallets = wallets.map((wallet) => {
      const { addressError, nameError } = validateWallet(wallet);

      const duplicateError =
        wallet.address.trim() !== "" &&
        wallets.some(
          (otherWallet) =>
            otherWallet.id !== wallet.id &&
            otherWallet.address.toLowerCase() === wallet.address.toLowerCase(),
        );

      if (addressError || nameError || duplicateError) isValid = false;

      return {
        ...wallet,
        errors: {
          address: addressError,
          name: nameError,
          duplicate: duplicateError,
        },
      };
    });
    setWallets(updatedWallets);
    return isValid;
  };

  const handleAddressChange = (id: number, address: string) => {
    const chain = isAddress(address) ? "evm" : ""; // Проверка isAddress
    setWallets((prev) =>
      prev.map((wallet) =>
        wallet.id === id ? { ...wallet, address, chain } : wallet,
      ),
    );
  };

  const handleSave = async () => {
    if (validateAllWallets()) {
      const walletsToCreate = wallets.filter((wallet) => wallet.isNew);
      const walletsToUpdate = wallets.filter((wallet) => !wallet.isNew);
      const walletIdsToDelete = userWallets
        .filter((savedWallet) =>
          wallets.every(
            (wallet) => wallet.databaseId !== savedWallet.databaseId,
          ),
        )
        .map((wallet) => wallet.databaseId!);
      try {
        if (walletsToCreate.length) await createWallets(walletsToCreate);
        if (walletsToUpdate.length) await updateWallets(walletsToUpdate);
        if (walletIdsToDelete.length) await deleteWallets(walletIdsToDelete);
        reloadUserWallets();

        navigate(`/${PORTFOLIO}`);
      } catch (error) {}
    }
  };

  const handleAddWallet = () => {
    setWallets((prev) => [
      ...prev,
      {
        ...walletDefaulValue,
        id: Date.now(),
        isNew: true,
        errors: { address: false, name: false, duplicate: false },
      },
    ]);
  };

  const handleUpdateWallet = (
    id: number,
    updatedFields: Partial<IUserWallet>,
  ) => {
    setWallets((prev) =>
      prev.map((wallet) =>
        wallet.id === id ? { ...wallet, ...updatedFields } : wallet,
      ),
    );
  };

  const handleDeleteWallet = (id: number) => {
    setWallets((prev) => prev.filter((wallet) => wallet.id !== id));
  };

  return (
    <div className={walletManagerStyles.container}>
      <div className={walletManagerStyles.walletsList}>
        {wallets.map((wallet) => (
          <WalletCard
            key={wallet.id}
            address={wallet.address}
            name={wallet.name}
            isMyWallet={wallet.isMyWallet}
            isEditable={true}
            hasAddressError={wallet.errors.address || wallet.errors.duplicate}
            addressErrorText={
              wallet.errors.duplicate ? DUPLICATE_ERROR : ADDRESS_ERROR
            }
            hasNameError={wallet.errors.name}
            nameErrorText={
              wallet.errors.name
                ? wallet.name.length > NAME_MAX_LENGTH
                  ? NAME_LENGTH_ERROR
                  : NAME_EMPTY_ERROR
                : ""
            }
            onAddressChange={(value) => handleAddressChange(wallet.id, value)} // Используем новую функцию
            onNameChange={(value) =>
              handleUpdateWallet(wallet.id, { name: value })
            }
            onMyWalletChange={(checked) =>
              handleUpdateWallet(wallet.id, { isMyWallet: checked })
            }
            onDelete={() => handleDeleteWallet(wallet.id)}
          />
        ))}
        {wallets.length < MAX_WALLETS_COUNT && (
          <SmallButton onClick={handleAddWallet}>Add another one</SmallButton>
        )}
      </div>
      <div
        className={walletManagerStyles.fixedSaveButton(
          isLayout ? NAVIGATION_BAR_HEIGHT : 0,
        )}
      >
        <div className={walletManagerStyles.buttonWrapper(isLayout ? 16 : 32)}>
          <Button variant="light" onClick={handleSave}>
            Track
          </Button>
        </div>
      </div>
    </div>
  );
};
