export const getShortAddress = (
  address: string,
  length: number = 10,
): string => {
  if (!address || address.length <= length + length) {
    return address;
  }
  const start = address.slice(0, length);
  const end = address.slice(-length);
  return `${start}...${end}`;
};
