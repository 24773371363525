import React from "react";
import { css } from "@emotion/css";
import { NavLink } from "react-router-dom";
import { PortfolioIcon } from "./icons/PortfolioIcon";
import { WalletsIcon } from "./icons/WalletsIcon";

const navigationBarStyles = {
  container: css`
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 36px;
    background: #1c1c1c;

    border-top: 1px solid #333;
  `,
  navItem: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    text-decoration: none;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;

    font-weight: 400;
    color: #c1c1c899;

    &.active {
      color: #cebcff;
    }
  `,
};

export const NavigationBar: React.FC = () => {
  return (
    <div className={navigationBarStyles.container}>
      <NavLink
        to="/portfolio"
        className={({ isActive }) =>
          `${navigationBarStyles.navItem} ${isActive ? "active" : ""}`
        }
      >
        <PortfolioIcon />
        Portfolio
      </NavLink>
      <NavLink
        to="/manage-wallets"
        className={({ isActive }) =>
          `${navigationBarStyles.navItem} ${isActive ? "active" : ""}`
        }
      >
        <WalletsIcon />
        Manage Wallets
      </NavLink>
    </div>
  );
};
