import React from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import { Layout } from "./components/Layout";
import { PortfolioPage } from "./components/pages/PortfolioPage";
import { ManageWalletsPage } from "./components/pages/ManageWalletsPage";
import "./App.css";
import { AuthorizationPage } from "./components/pages/AuthorizationPage";
import { UserWalletProvider } from "./components/context/UserWalletContext";
import { WalletFilterPage } from "./components/pages/WalletFilterPage";
import {
  ANOTHER_WALLETS,
  AUTHORIZATION,
  MANAGE_WALLETS,
  MY_WALLETS,
  PORTFOLIO,
  WALLETS_FILTER,
} from "./shared/constants/routes";

const App: React.FC = () => {
  return (
    <UserWalletProvider>
      <Router>
        <Routes>
          <Route path={`/${AUTHORIZATION}`} element={<AuthorizationPage />} />
          <Route path={`/${WALLETS_FILTER}`} element={<WalletFilterPage />} />
          <Route path="/" element={<Layout />}>
            <Route path={`${PORTFOLIO}`}>
              <Route path={`${MY_WALLETS}`} element={<PortfolioPage />} />
              <Route path={`${ANOTHER_WALLETS}`} element={<PortfolioPage />} />
              <Route index element={<Navigate to={`${MY_WALLETS}`} />} />
            </Route>
            <Route path={`${MANAGE_WALLETS}`} element={<ManageWalletsPage />} />
            <Route index element={<Navigate to={`/${AUTHORIZATION}`} />} />
          </Route>
          <Route path="*" element={<Navigate to={`/${AUTHORIZATION}`} />} />
        </Routes>
      </Router>
    </UserWalletProvider>
  );
};

export default App;
