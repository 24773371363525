import React, { useEffect, useState } from "react";
import { css } from "@emotion/css";
import { useLocation, useNavigate } from "react-router-dom";
import { Tabs } from "../uiKit/Tabs";
import { useUserWallets } from "../context/UserWalletContext";
import { WalletsContent } from "../WalletsContent";
import { IUserWallet } from "../../shared/intefaces/IUserWallet";
import {
  ANOTHER_WALLETS,
  AUTHORIZATION,
  MY_WALLETS,
  PORTFOLIO,
} from "../../shared/constants/routes";
import { EmptyState } from "../EmptyState";

const portfolioPageStyles = {
  container: css`
    display: flex;
    flex-direction: column;
    color: #fff;
    padding: 16px;
  `,
  loadingMessage: css`
    font-family: "Space Mono", sans-serif;
    font-size: 16px;
    color: #fff;
    text-align: center;
    margin-top: 40vh;
  `,
  tabsContainer: css`
    margin-bottom: 32px;
  `,
};

export const PortfolioPage: React.FC = () => {
  const {
    userWallets,
    loading,
    mainWallet,
    anotherWallet,
    setMainWallet,
    setAnotherWallet,
  } = useUserWallets();
  const navigate = useNavigate();
  const location = useLocation();

  const activeTabFromPath = location.pathname.endsWith(`/${MY_WALLETS}`)
    ? 0
    : 1;

  const [myWallets, setMyWallets] = useState<IUserWallet[]>([]);
  const [anotherWallets, setAnotherWallets] = useState<IUserWallet[]>([]);

  useEffect(() => {
    if (!loading && userWallets.length === 0) {
      navigate(`/${AUTHORIZATION}`);
    }
  }, [loading, userWallets, navigate]);

  useEffect(() => {
    if (userWallets.length) {
      const myWalletsData = userWallets.filter((wallet) => wallet.isMyWallet);
      if (myWalletsData.length) {
        setMyWallets(mainWallet === null ? myWalletsData : [mainWallet]);
      }
      if (mainWallet === null && myWalletsData.length === 1) {
        setMainWallet(myWalletsData[0]);
      }
      const anotherWalletData = userWallets.filter(
        (wallet) => !wallet.isMyWallet,
      );
      if (anotherWalletData.length) {
        setAnotherWallets(
          anotherWallet === null
            ? anotherWalletData.slice(0, 1)
            : [anotherWallet],
        );
        if (anotherWallet === null) {
          setAnotherWallet(anotherWalletData[0]);
        }
      }
    }
  }, [userWallets, mainWallet, anotherWallet]);

  const handleTabChange = (newIndex: number) => {
    if (newIndex === 0) {
      navigate(`/${PORTFOLIO}/${MY_WALLETS}`);
    } else {
      navigate(`/${PORTFOLIO}/${ANOTHER_WALLETS}`);
    }
  };

  if (loading) {
    return (
      <div className={portfolioPageStyles.loadingMessage}>
        Loading wallets...
      </div>
    );
  }

  return (
    <div className={portfolioPageStyles.container}>
      <div className={portfolioPageStyles.tabsContainer}>
        <Tabs
          tabs={[{ label: "My wallets" }, { label: "Another" }]}
          activeIndex={activeTabFromPath}
          onTabChange={handleTabChange}
        />
      </div>
      {activeTabFromPath === 0 &&
        (myWallets.length > 0 ? (
          <WalletsContent
            walletType="my"
            wallets={myWallets}
            selectedWallet={mainWallet}
          />
        ) : (
          <EmptyState message={"No wallets found"} />
        ))}

      {activeTabFromPath === 1 &&
        (anotherWallets.length > 0 ? (
          <WalletsContent
            walletType="another"
            wallets={anotherWallets}
            selectedWallet={anotherWallet}
          />
        ) : (
          <EmptyState message={"No wallets found"} />
        ))}
    </div>
  );
};
