import React, { useEffect, useState } from "react";
import { css } from "@emotion/css";
import { useNavigate } from "react-router-dom";
import { WalletCard } from "../WalletCard";
import { Button } from "../uiKit/Button";
import { WalletManager } from "../WalletManager";
import { useUserWallets } from "../context/UserWalletContext";
import { PORTFOLIO } from "../../shared/constants/routes";
import { walletDefaulValue } from "../../shared/constants/walletDefaulValue";
import {
  isWalletValid,
  validateWallet,
} from "../../shared/utils/validateWallet";
import {
  ADDRESS_ERROR,
  NAME_EMPTY_ERROR,
  NAME_LENGTH_ERROR,
} from "../../shared/constants/errors";
import { FrogIcon } from "../icons/FrogIcon";
import { BackButton } from "../uiKit/BackButton";
import { NAME_MAX_LENGTH } from "../../shared/constants/numbers";
import { WebStub } from "../WebStub";

const authorizationPageStyles = {
  authContainer: css`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 32px 16px;
    color: #fff;
    box-sizing: border-box;
  `,
  wrapper: css`
    margin-bottom: 20px;
  `,
  vmHeader: css`
    color: #fff;
    font-family: "Space Mono", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    padding-left: 16px;
    margin-bottom: 24px;
  `,

  contentWrapper: css`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  `,
  header: css`
    text-align: center;
    margin-bottom: 40px;
  `,
  frogWrapper: css`
    margin-bottom: 32px;
  `,
  mainText: css`
    color: #fff;
    text-align: center;
    font-family: "Space Mono", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
    margin-bottom: 12px;
  `,
  subText: css`
    color: #fff;
    text-align: center;
    font-feature-settings:
      "liga" off,
      "clig" off;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.43px;
  `,
  cardWrapper: css`
    width: 100%;
    margin-bottom: 40px;
  `,
  backButton: css`
    padding-left: 16px;
    padding-top: 16px;
    margin-bottom: 24px;
  `,
  footer: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
  `,
  bulkImportWrapper: css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
    width: 100%;
  `,
  bulkImportLine: css`
    flex: 1;
    height: 1px;
    background: #444;
  `,
  bulkImportText: css`
    margin: 0 12px;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
  `,
};

export const AuthorizationPage: React.FC = () => {
  const navigate = useNavigate();
  const { userId, userWallets, createWallets, reloadUserWallets, loading } =
    useUserWallets();
  const [showWalletManager, setShowWalletManager] = useState(false);
  const [wallet, setWallet] = useState({
    ...walletDefaulValue,
    isNew: true,
  });

  useEffect(() => {
    reloadUserWallets();
  }, [reloadUserWallets]);

  useEffect(() => {
    if (!loading && userWallets.length > 0 && userId) {
      navigate(`/${PORTFOLIO}`);
    }
  }, [loading, userWallets, userId, navigate]);

  if (!userId) {
    return (
      <div className={authorizationPageStyles.authContainer}>
        <WebStub />
      </div>
    );
  }

  const handleTrack = async () => {
    const { addressError, nameError } = validateWallet(wallet);

    setWallet((prev) => ({
      ...prev,
      errors: { address: addressError, name: nameError, duplicate: false },
    }));

    if (isWalletValid(wallet)) {
      await createWallets([wallet]);
      reloadUserWallets();
    }
  };

  return (
    <>
      {showWalletManager ? (
        <div>
          <div className={authorizationPageStyles.backButton}>
            <BackButton onClick={() => setShowWalletManager(false)} />
          </div>
          <div className={authorizationPageStyles.vmHeader}>More wallets</div>
          <div className={authorizationPageStyles.wrapper}>
            <WalletManager isLayout={false} />
          </div>
        </div>
      ) : (
        <div className={authorizationPageStyles.authContainer}>
          <div className={authorizationPageStyles.contentWrapper}>
            <div className={authorizationPageStyles.frogWrapper}>
              <FrogIcon />
            </div>
            <div className={authorizationPageStyles.header}>
              <div className={authorizationPageStyles.mainText}>
                Add your wallet
              </div>
              <div className={authorizationPageStyles.subText}>
                You may track coins, tokens, protocols positions and NFT on 60+
                chains
              </div>
            </div>
            <div className={authorizationPageStyles.cardWrapper}>
              <WalletCard
                address={wallet.address}
                name={wallet.name}
                isMyWallet={wallet.isMyWallet}
                isEditable={false}
                hasAddressError={Boolean(wallet.errors.address)}
                addressErrorText={wallet.errors.address ? ADDRESS_ERROR : ""}
                hasNameError={Boolean(wallet.errors.name)}
                nameErrorText={
                  wallet.errors.name
                    ? wallet.name.length > NAME_MAX_LENGTH
                      ? NAME_LENGTH_ERROR
                      : NAME_EMPTY_ERROR
                    : ""
                }
                onAddressChange={(value) =>
                  setWallet((prev) => ({ ...prev, address: value }))
                }
                onNameChange={(value) =>
                  setWallet((prev) => ({ ...prev, name: value }))
                }
                onMyWalletChange={(checked) =>
                  setWallet((prev) => ({ ...prev, isMyWallet: checked }))
                }
              />
            </div>
            <Button variant="light" onClick={handleTrack}>
              Track
            </Button>
          </div>
          <div className={authorizationPageStyles.footer}>
            <div className={authorizationPageStyles.bulkImportWrapper}>
              <div className={authorizationPageStyles.bulkImportLine}></div>
              <span className={authorizationPageStyles.bulkImportText}>
                or bulk import
              </span>
              <div className={authorizationPageStyles.bulkImportLine}></div>
            </div>
            <Button variant="dark" onClick={() => setShowWalletManager(true)}>
              Add more wallets
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
