export const PortfolioIcon = () => {
  return (
    <svg
      width="27"
      height="23"
      viewBox="0 0 27 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.501 15.2666H14.999C15.415 15.2666 15.7373 15.1582 15.9658 14.9414C16.1943 14.7188 16.3086 14.4023 16.3086 13.9922V13.0957C17.4219 13.0195 18.5322 12.8936 19.6396 12.7178C20.7471 12.5361 21.8662 12.293 22.9971 11.9883C24.1279 11.6836 25.2852 11.3027 26.4687 10.8457V8.95605C25.0918 9.56543 23.6855 10.0576 22.25 10.4326C20.8144 10.8076 19.3467 11.0801 17.8467 11.25C16.3525 11.4199 14.8203 11.5049 13.25 11.5049C11.6855 11.5049 10.1533 11.4199 8.65332 11.25C7.15332 11.0801 5.68554 10.8076 4.25 10.4326C2.81445 10.0576 1.4082 9.56543 0.0312462 8.95605V10.8457C1.21484 11.3027 2.37207 11.6836 3.50293 11.9883C4.63964 12.293 5.76171 12.5361 6.86914 12.7178C7.97656 12.8936 9.08398 13.0195 10.1914 13.0957V13.9922C10.1914 14.4023 10.3057 14.7188 10.5342 14.9414C10.7627 15.1582 11.085 15.2666 11.501 15.2666ZM3.52929 22.79C2.375 22.79 1.50195 22.5 0.910152 21.9199C0.324215 21.3398 0.0312462 20.4756 0.0312462 19.3271V8.15625C0.0312462 7.00195 0.324215 6.13477 0.910152 5.55469C1.50195 4.97461 2.375 4.68457 3.52929 4.68457H22.9707C24.1309 4.68457 25.0039 4.97461 25.5898 5.55469C26.1758 6.13477 26.4687 7.00195 26.4687 8.15625V19.3271C26.4687 20.4756 26.1758 21.3398 25.5898 21.9199C25.0039 22.5 24.1309 22.79 22.9707 22.79H3.52929ZM7.43164 5.52832V3.50684C7.43164 2.49902 7.70996 1.74902 8.2666 1.25684C8.82324 0.764648 9.58203 0.518555 10.543 0.518555H15.9482C16.9561 0.518555 17.7266 0.764648 18.2598 1.25684C18.7988 1.74902 19.0684 2.49902 19.0684 3.50684V5.51074H17.2666V3.49805C17.2666 3.07617 17.1465 2.75391 16.9062 2.53125C16.6719 2.30859 16.3379 2.19727 15.9043 2.19727H10.5957C10.1621 2.19727 9.82519 2.30859 9.58496 2.53125C9.35058 2.75391 9.23339 3.07617 9.23339 3.49805V5.52832H7.43164Z"
        fill="currentColor"
      />
    </svg>
  );
};
