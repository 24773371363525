import React from "react";

export const CheckIcon = () => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.41406 14.4453C5.92969 14.4453 5.52604 14.2526 5.20312 13.8672L1.17188 8.98438C1.03125 8.82292 0.932292 8.66667 0.875 8.51562C0.817708 8.36458 0.789062 8.20833 0.789062 8.04688C0.789062 7.67708 0.911458 7.3724 1.15625 7.13281C1.40625 6.88802 1.71875 6.76562 2.09375 6.76562C2.51042 6.76562 2.85677 6.9349 3.13281 7.27344L6.38281 11.2969L12.7656 1.21094C12.9271 0.966146 13.0938 0.794271 13.2656 0.695312C13.4427 0.591146 13.6589 0.539062 13.9141 0.539062C14.2891 0.539062 14.599 0.658854 14.8438 0.898438C15.0885 1.13802 15.2109 1.4401 15.2109 1.80469C15.2109 1.9401 15.1875 2.08073 15.1406 2.22656C15.0938 2.3724 15.0208 2.52344 14.9219 2.67969L7.64844 13.8125C7.36198 14.2344 6.95052 14.4453 6.41406 14.4453Z"
        fill="#0F0F0F"
      />
    </svg>
  );
};
