import React from "react";
import { css } from "@emotion/css";
import { ZoomIcon } from "./icons/ZoomIcon";

const emptyStateStyles = {
  container: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(124, 124, 124, 0.5);
    border-radius: 8px;
    padding: 24px;
  `,
  iconWrapper: css`
    margin-bottom: 8px;
  `,
  text: css`
    font-family: "Space Mono", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 590;
    line-height: 20px; /* 125% */
    letter-spacing: -0.43px;
    color: #ffffff;
  `,
};

interface EmptyStateProps {
  message: string; // Текст сообщения, который будет отображаться
}

export const EmptyState: React.FC<EmptyStateProps> = ({ message }) => {
  return (
    <div className={emptyStateStyles.container}>
      <div className={emptyStateStyles.iconWrapper}>
        <ZoomIcon />
      </div>
      <span className={emptyStateStyles.text}>{message}</span>
    </div>
  );
};
