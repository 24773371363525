import React from "react";
import { css } from "@emotion/css";

export const InputStyles = {
  input: (hasError: boolean) => css`
    width: 100%;
    padding: 14px 16px;
    font-size: 16px;
    border: 1px solid ${hasError ? "#F7C5C5" : "#1C1C1C"};
    font-family: "Open Sans", sans-serif;
    border-radius: 8px;
    background: #1c1c1c;
    color: #ffffff;
    outline: none;
    box-sizing: border-box;
    transition: border-color 0.3s;

    &::placeholder {
      color: #c1c1c899;
    }
  `,
  errorText: css`
    font-family: "Open Sans", sans-serif;
    color: #f7c5c5;
    padding-top: 8px;
    padding-left: 16px;
    font-size: 14px;
  `,
};

type InputProps = {
  value: string;
  placeholder?: string;
  hasError: boolean;
  errorText?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Input: React.FC<InputProps> = ({
  value,
  placeholder,
  hasError,
  errorText,
  onChange,
}) => {
  return (
    <div>
      <input
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        className={InputStyles.input(hasError)}
      />
      {hasError && errorText && (
        <div className={InputStyles.errorText}>{errorText}</div>
      )}
    </div>
  );
};
