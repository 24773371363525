import React, { useEffect, useState } from "react";
import { css } from "@emotion/css";
import { IUserWallet } from "../shared/intefaces/IUserWallet";
import { FilterWalletListItem } from "./FilterWalletListItem";
import { useUserWallets } from "./context/UserWalletContext";

const ALL_WALLETS_ID = 0;

const walletListStyles = {
  container: css`
    flex: 1;
    overflow-y: scroll;
    ms-overflow-style: none;
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none;
    }
  `,
};

interface FilterWalletListProps {
  walletType: "my" | "another";
  wallets: IUserWallet[];
  onWalletSelect: (wallet: IUserWallet | null) => void;
}

export const FilterWalletList: React.FC<FilterWalletListProps> = ({
  walletType,
  wallets,
  onWalletSelect,
}) => {
  const { mainWallet, anotherWallet, loading, userWallets } = useUserWallets();
  const [selectedWalletId, setSelectedWalletId] = useState<number | null>(null);

  const myWalletsCount = userWallets.filter(
    (wallet) => wallet.isMyWallet,
  ).length;

  useEffect(() => {
    if (walletType === "my") {
      if (mainWallet) {
        setSelectedWalletId(mainWallet.id);
      } else {
        if (myWalletsCount === 1) {
          setSelectedWalletId(wallets[0].id);
        } else {
          setSelectedWalletId(ALL_WALLETS_ID);
        }
      }
    } else if (walletType === "another") {
      if (anotherWallet) {
        setSelectedWalletId(anotherWallet.id);
      }
    }
  }, [mainWallet, anotherWallet, loading]);
  const handleWalletClick = (wallet: IUserWallet | null) => {
    setSelectedWalletId(wallet ? wallet.id : ALL_WALLETS_ID);
    onWalletSelect(wallet);
  };

  return (
    <div className={walletListStyles.container}>
      {walletType === "my" && myWalletsCount > 1 && (
        <FilterWalletListItem
          walletName={`${myWalletsCount} wallets`}
          walletAddress={"All Wallets"}
          isSelected={selectedWalletId === ALL_WALLETS_ID}
          onClick={() => handleWalletClick(null)}
        />
      )}
      {wallets.map((wallet) => (
        <FilterWalletListItem
          key={wallet.id}
          walletAddress={wallet.address}
          walletName={wallet.name}
          isSelected={selectedWalletId === wallet.id}
          onClick={() => handleWalletClick(wallet)}
        />
      ))}
    </div>
  );
};
