import React from "react";
import { css } from "@emotion/css";
import { NFTInfo } from "./NftInfo";
import { INFT } from "../shared/intefaces/INFT";
import { EmptyState } from "./EmptyState";

const nftListStyles = {
  container: css`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 24px;
  `,
};

interface NFTListProps {
  data: INFT[];
}

export const NFTList: React.FC<NFTListProps> = ({ data }) => {
  return (
    <div className={nftListStyles.container}>
      {data.map((nft, index) => (
        <NFTInfo
          nftImage={nft.image}
          nftName={nft.name}
          chainImage={nft.chainLogo}
          price={nft.price}
          balance={nft.amount}
          value={nft.value}
          tag={nft.walletName}
          key={index}
        />
      ))}
      {data.length === 0 && <EmptyState message={"No NFT found"} />}
    </div>
  );
};
