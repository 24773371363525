import { IUserWallet } from "../intefaces/IUserWallet";
import { isAddress } from "viem";

export interface WalletValidationResult {
  addressError: boolean;
  nameError: boolean;
}

export const validateWallet = (wallet: IUserWallet): WalletValidationResult => {
  const addressError = !isAddress(wallet.address);
  const nameError = wallet.name.trim() === "" || wallet.name.trim().length > 30;
  return { addressError, nameError };
};

export const isWalletValid = (wallet: IUserWallet): boolean => {
  const { addressError, nameError } = validateWallet(wallet);
  return !addressError && !nameError;
};
