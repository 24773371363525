import { BACKEND_API } from "../constants/urls";

export const deleteWallets = async (
  walletIds: number[],
  token: string,
): Promise<void> => {
  try {
    for (const walletId of walletIds) {
      const response = await fetch(`${BACKEND_API}/wallets/delete`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ walletIds: [walletId] }),
      });

      const data = await response.json();

      if (response.status !== 200 || data.status !== 200) {
        throw new Error(`Failed to delete wallet with ID ${walletId}`);
      }
    }
  } catch (error) {
    throw error;
  }
};
